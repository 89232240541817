@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");
@import "vars";

@tailwind base;
@tailwind components;
@tailwind utilities;

//@media screen and (orientation: landscape) and (max-width: 786px){
//  html {
//    /* Rotate the content container */
//    transform: rotate(-90deg);
//    transform-origin: left top;
//    /* Set content width to viewport height */
//    width: 100vh;
//    /* Set content height to viewport width */
//    min-height: 100vw;
//    position: absolute;
//    top: 100%;
//    left: 0;
//  }
//}

html,
body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  min-height: 100vh;
  font-family: "Poppins", sans-serif;
  overflow-x: hidden;
}

* {
  font-family: "Poppins", sans-serif;
}

:root {
  --mainColor: #415b3f;
  --bgGradientGreen: #91b257;
  --bgGradientWhite: rgba(145, 177, 88, 0);
  --linkColor: #69bc7b;
  --textColor: #3d3d3d;
  --labelColor: #3d3d3d;
  --accountColor: #595f77;
  --black: #292929;
  --blue: #387dd3;
  --darkBlue: #1a5bab;
  --red: #e05757;
  --lightGrey: #c4c4c4;
  --bgGrey: #ededed;
  --lightGreen: #69bc7b;
  --greenGradient: linear-gradient(180deg, #415b3f 59.27%, #253424 100%);
  --greenGradientHorizontal: linear-gradient(93.98deg, #5c9f6a -59.13%, #3b5942 82.37%);
}

a {
  color: var(--linkColor);
}

* {
  box-sizing: border-box;
  font-size: 15px;
}

h2 {
  color: black;
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
}

label {
  color: var(--labelColor);
  display: block;

  &:not(:last-of-type) {
    margin-bottom: 34px;
  }

  select,
  input {
    margin-top: 11px;
    display: block;
    width: 100%;
    padding: 7px;
  }
}

button,
input[type="submit"] {
  border-radius: 35px;
  // padding: 13px 0 12px 0;
  text-align: center;
  cursor: pointer;
  background: var(--mainColor);
  border: none;
  // height: 45px;
  color: white;
  // width: 100%;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.sk-fading-circle {
  margin: 100px auto;
  width: 40px;
  height: 40px;
  position: relative;
}

.sk-fading-circle .sk-circle {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.sk-fading-circle .sk-circle:before {
  content: "";
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  background-color: var(--linkColor);
  border-radius: 100%;
  animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
}

.sk-fading-circle .sk-circle2 {
  transform: rotate(30deg);
}

.sk-fading-circle .sk-circle3 {
  transform: rotate(60deg);
}

.sk-fading-circle .sk-circle4 {
  transform: rotate(90deg);
}

.sk-fading-circle .sk-circle5 {
  transform: rotate(120deg);
}

.sk-fading-circle .sk-circle6 {
  transform: rotate(150deg);
}

.sk-fading-circle .sk-circle7 {
  transform: rotate(180deg);
}

.sk-fading-circle .sk-circle8 {
  transform: rotate(210deg);
}

.sk-fading-circle .sk-circle9 {
  transform: rotate(240deg);
}

.sk-fading-circle .sk-circle10 {
  transform: rotate(270deg);
}

.sk-fading-circle .sk-circle11 {
  transform: rotate(300deg);
}

.sk-fading-circle .sk-circle12 {
  transform: rotate(330deg);
}

.sk-fading-circle .sk-circle2:before {
  animation-delay: -1.1s;
}

.sk-fading-circle .sk-circle3:before {
  animation-delay: -1s;
}

.sk-fading-circle .sk-circle4:before {
  animation-delay: -0.9s;
}

.sk-fading-circle .sk-circle5:before {
  animation-delay: -0.8s;
}

.sk-fading-circle .sk-circle6:before {
  animation-delay: -0.7s;
}

.sk-fading-circle .sk-circle7:before {
  animation-delay: -0.6s;
}

.sk-fading-circle .sk-circle8:before {
  animation-delay: -0.5s;
}

.sk-fading-circle .sk-circle9:before {
  animation-delay: -0.4s;
}

.sk-fading-circle .sk-circle10:before {
  animation-delay: -0.3s;
}

.sk-fading-circle .sk-circle11:before {
  animation-delay: -0.2s;
}

.sk-fading-circle .sk-circle12:before {
  animation-delay: -0.1s;
}

@-webkit-keyframes sk-circleFadeDelay {
  0%,
  39%,
  100% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
}

@keyframes sk-circleFadeDelay {
  0%,
  39%,
  100% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
}

.mobileHide {
  @media screen and (max-width: $mobileScreen) {
    display: none !important;
  }
}

.mobileShow {
  display: none !important;
  @media screen and (max-width: $mobileScreen) {
    display: unset !important;
  }
}

.zoomImageWrapper {
  height: 100% !important;
}

// Scroll bar style
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  background-color: #f8f8f8;

  @media screen and (max-width: $mobileScreen) {
    display: none;
  }
}

::-webkit-scrollbar {
  width: 8px;
  background-color: #f8f8f8;

  @media screen and (max-width: $mobileScreen) {
    display: none;
  }
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.15);
  background-color: #dcdcdc;

  @media screen and (max-width: $mobileScreen) {
    display: none;
  }
}

.image-style-side {
  float: right !important;
  margin: unset;
  @media screen and (max-width: $mobileScreen) {
    float: none !important;
    width: 100% !important;
    padding-top: 1rem !important;
  }
}

.image_resized:not(.image-style-side) {
  @media screen and (max-width: $mobileScreen) {
    width: 100% !important;
  }
}

.aboutSection {
  .table {
    background: transparent;
    padding-top: 1rem;
  }
  .table table {
    box-shadow: none;
    background: transparent;
    border-radius: 0;
    border: none;

    tr {
      @media screen and (max-width: $mobileScreen) {
        display: flex;
        flex-direction: column;
        gap: 1rem;
      }
    }

    tr,
    td,
    tr:first-of-type td {
      padding: 0;
      background: transparent !important;
    }

    td {
      @include defaultText;
    }

    td:first-of-type {
      width: 50%;
      p {
        padding-right: 25px;
      }
      @media screen and (max-width: $mobileScreen) {
        padding-right: 0;
        width: 100%;
      }
      figcaption {
        //width: 100%;
        padding-top: 1rem;
        @media screen and (max-width: $mobileScreen) {
          width: 100%;
        }
      }
    }
  }
}

.hideMobile {
  @media screen and (max-width: $mobileScreen) {
    display: none !important;
  }
}

#expiredSession {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    display: none;
    text-align: center;
    line-height: 2rem;
    font-size: 16px;
    padding: 0 1rem;
  }
}

.fade-in {
  display: block;
  animation: fade-in 0.7s ease-in forwards;
}

.fade-out {
  animation: fade-out 0.7s ease-out forwards;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
